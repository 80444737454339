module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"fastweb.dev","short_name":"fastweb.dev","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/logos/fw-icon-green.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f62429e237669f8ca3d135f8705ac8e5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.fastweb.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KC44256","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"fw-dev-gatsby-demo","accessToken":"MC5aTTBhelJFQUFDRUFibWkw.FO-_ve-_vXUR77-9Mu-_ve-_ve-_ve-_vWsk77-9AiY2C2bvv73vv70K77-9AX8N77-9R0jvv73vv71z","lang":"en-us","promptForAccessToken":true,"apiEndpoint":"https://fw-dev-gatsby-demo.cdn.prismic.io/api/v2","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
